<itq-sidebar [width]="300">
  <div class="flex m-4">
    <itq-nav-button
      class="flex-1"
      [openDirection]="{ vertical: 'down', horizontal: 'right' }"
      [openPosition]="'outside'"
      [materialStyle]="'flat'"
      [size]="Sizes.BLOCK"
      [color]="'primary'"
      [width]="200"
      [template]="addTemplate"
    >
      <fa-icon [icon]="['far', 'plus']"></fa-icon>
      NEW
    </itq-nav-button>
  </div>
  <h1 class="font-semibold ml-4 text-17">BOARDS</h1>
  <itq-input
    class="p-4"
    [(ngModel)]="query"
    [placeholder]="'Search boards...'"
    [icon]="'search'"
  ></itq-input>
  <ejs-treeview
    class="overflow-auto board__navigation_container"
    #treeviewObj
    id="treeelement"
    [fields]="dataSource | adaptTreeDataSource: query"
    [allowDragAndDrop]="query || query === ''"
    (created)="onCreate()"
    (nodeEdited)="onEditedNode($event)"
    (nodeEditing)="onEditingNode()"
    (nodeDragStart)="dragStart($event)"
    (nodeDragStop)="dragStop($event)"
    (nodeDragging)="nodeDrag($event)"
    (nodeDropped)="nodeDropped()"
    (nodeSelecting)="onSelecting($event)"
    (nodeSelected)="onLoadBoard($event)"
  >
    <ng-template #nodeTemplate="" let-data>
      <div
        [ngClass]="[
          data.isGroup ? 'cursor-default' : 'cursor-pointer',
          'flex',
          'items-center',
          'flex-1',
          'justify-between'
        ]"
      >
        <span class="overflow-hidden text-ellipsis max-w-[161px]">{{
          data?.name
        }}</span>
        <itq-nav-button
          [materialStyle]="'basic'"
          [width]="200"
          [openDirection]="{
            vertical: 'down',
            horizontal: 'right'
          }"
          [openPosition]="'outside'"
          [template]="data.isGroup ? groupMoreTemplate : boardMoreTemplate"
          [templateData]="data"
        >
          <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
        </itq-nav-button>
      </div> </ng-template
  ></ejs-treeview>
</itq-sidebar>
<ng-template #addTemplate>
  <div class="board__dropdown_container">
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onCreateBoard()"
    >
      <itq-svg [icon]="'dashboard'" class="w-4 mx-1"></itq-svg>
      New board
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [align]="Align.START"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      (clickEvent)="onCreateGroup()"
    >
      <fa-icon [icon]="['far', 'folder']"></fa-icon>
      New group
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #groupMoreTemplate let-node>
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [padding]="Sizes['X-SM']"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (clickEvent)="renameGroup(node)"
  >
    <fa-icon [icon]="['far', 'pencil']"></fa-icon>
    Rename group
  </itq-mat-button>
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [padding]="Sizes['X-SM']"
    [type]="'button'"
    [align]="Align.START"
    [disabled]="node | containsDefaultBoard"
    [materialStyle]="'basic'"
    (clickEvent)="onDeleteGroup(node)"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Delete group
  </itq-mat-button>
</ng-template>

<ng-template #boardMoreTemplate let-node>
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [padding]="Sizes['X-SM']"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (clickEvent)="onRenameBoard(node)"
  >
    <fa-icon [icon]="['far', 'pencil']"></fa-icon>
    Rename board
  </itq-mat-button>
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [padding]="Sizes['X-SM']"
    [type]="'button'"
    [align]="Align.START"
    [disabled]="node.defaultBoard"
    [materialStyle]="'basic'"
    (clickEvent)="onSetAsDefault(node)"
  >
    <fa-icon [icon]="['far', 'star']"></fa-icon>
    Set as default
  </itq-mat-button>
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [padding]="Sizes['X-SM']"
    [disabled]="node.defaultBoard"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (clickEvent)="onDeleteBoard(node)"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Delete board
  </itq-mat-button>
</ng-template>
