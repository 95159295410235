<div class="flex flex-col w-full max-h-[160px]">
  @for (item of events; track item.id; let index = $index) {
    <div
      [ngClass]="{
        'flex flex-col': true,
        'border-b border-border pb-4 mb-4':
          events?.length > 1 && index !== events?.length - 1
      }"
      (click)="onClickEvent(item)"
    >
      <div class="flex">
        <mat-icon
          class="mr-2 text-4xl"
          [fontIcon]="
            item.team === EventTeams.SECURITY_TEAM ? 'lock' : 'sports_esports'
          "
        ></mat-icon>
        <div class="flex flex-col">
          <span
            class="font-semibold pr-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]"
            >{{ item.name }}</span
          ><span class="text-xs">{{ item.typeName }}</span>
        </div>
      </div>
      @if (item.description && allowDrilldown && expandedEvent === item.id) {
        <p class="break-all pt-2 text-sm">
          {{ item.description }}
        </p>
      }
    </div>
  }
</div>
