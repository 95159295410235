import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CustomOverlayConfig,
  CUSTOM_OVERLAY_DATA,
  DialogComponent,
  DialogTypes,
  CustomOverlayService,
} from '@intorqa-ui/core';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { ISegment } from './interfaces/widget-settings.interface';
import { DiscordNavigationItem } from './models/discord-navigation-item.model';
import { NavigationHistoryItem } from './models/navigation-history-item.model';
import { NavigationHistory } from './models/navigation-history.model';
import { ResponsivePanels } from './models/responsive-panels';
import { WidgetService } from '@portal/boards/services/widget.service';
import { WidgetSettingsService } from './services/widget-settings.service';

@Component({
  selector: 'itq-widget-settings',
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.scss'],
})
export class WidgetSettingsComponent implements OnInit, OnDestroy {
  public AnalysisTypes = AnalysisTypes;
  public selectedNavigationHistory: NavigationHistoryItem;
  public expandedNavigation = true;
  public navigationHistory: NavigationHistory;
  public segment: any;
  public document: ISegment;
  public form: FormGroup;
  public showLoader = false;
  private subscriptions = new Subscription();

  readonly WidgetActions = WidgetActions;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit(): void {
    this.bindLoaderSubscription();
    this.selectedNavigationHistory = this.config.data
      ?.navigationItem as NavigationHistoryItem;
    this.navigationHistory = new NavigationHistory([
      this.selectedNavigationHistory,
    ]);
    this.form = this.selectedNavigationHistory.form;
    this.document = this.config.data?.articleDetail;
    this.segment = cloneDeep(this.config?.data?.segment);

    this.widgetService.responsivePanels = new ResponsivePanels(
      false,
      true,
      this.document ? true : false,
      false,
    );

    this.subscriptions.add(
      this.widgetService.updateNavigationItem$.subscribe(
        (response: NavigationHistoryItem) => {
          this.navigationHistory.items = this.navigationHistory?.items?.map(
            (item: NavigationHistoryItem) => {
              return item.id === response.id ? response : item;
            },
          );
        },
      ),
    );

    this.subscriptions.add(
      this.widgetService.drilldownObservable.subscribe(
        (response: NavigationHistoryItem) => {
          const navHitoryItem = this.navigationHistory.find(response);
          if (navHitoryItem) {
            this.onLoadHistoryItem(navHitoryItem);
          } else {
            this.addNavigationHistoryItem(response);
            this.document = undefined;
            this.form = response.form;
          }
        },
      ),
    );

    this.subscriptions.add(
      this.widgetService.updateSegmentObservable.subscribe(
        (document: ISegment) => {
          this.document = document;
          this.widgetService.responsivePanels.articleDetail = this.document
            ? true
            : false;
          this.widgetService.responsivePanels$.next();
        },
      ),
    );
  }

  private bindLoaderSubscription(): void {
    this.subscriptions.add(
      this.widgetSettingsService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addNavigationHistoryItem(item: NavigationHistoryItem): void {
    if (!(item instanceof DiscordNavigationItem)) {
      item.segment = undefined;
    }
    debugger;
    if (!this.navigationHistory.includes(item)) {
      this.navigationHistory.add(item);
    }
    this.selectedNavigationHistory = item;
    this.widgetService.responsivePanels.navigationHistory =
      this.navigationHistory.items.length > 1;
  }

  public onDeleteHistoryItem(item: NavigationHistoryItem): void {
    this.navigationHistory.items = this.navigationHistory.items.filter(
      (elem: NavigationHistoryItem) => {
        return elem.item.name !== item.item.name;
      },
    );
    this.selectedNavigationHistory =
      this.navigationHistory.items[this.navigationHistory.items.length - 1];
    this.document = undefined;
    this.form.reset();
    this.widgetService.responsivePanels.navigationHistory =
      this.navigationHistory.items.length > 1;
  }

  public onLoadHistoryItem(item: NavigationHistoryItem): void {
    if (this.selectedNavigationHistory.id === 'create_drilldown') {
      this.customOverlayService.openCustom(
        {
          title: 'Cancel your tag?',
          message: `If  you leave this screen, the tag you are creating will be lost. <br /><br /> Are you sure you wish to load a previous step?`,
          icon: ['far', 'question-circle'],
          size: '4x',
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.navigationHistory.items = this.navigationHistory.items.filter(
              (elem: NavigationHistoryItem) => {
                return elem.id !== item.id;
              },
            );
            this.document = item.segment;
            this.form = cloneDeep(item.form);
            this.selectedNavigationHistory = item;
          }
        },
      );
    } else {
      this.form = cloneDeep(item.form);
      this.selectedNavigationHistory = item;
    }
  }

  public onToggle(): void {
    this.expandedNavigation = !this.expandedNavigation;
    this.widgetService.responsivePanels.navigationHistory =
      this.expandedNavigation;
    this.widgetService.responsivePanels$.next();
  }
}
