import { Type } from '@angular/core';
import { TagCategory, TimelineOrientation } from '@intorqa-ui/core';
import { TimelineWidgetComponent } from '@portal/boards/components/widget/components/timeline-widget/timeline-widget.component';
import { WidgetEventTimelineComponent } from '@portal/boards/components/widget/components/widget-event-timeline/widget-event-timeline.component';
import { WidgetTagAnalysisComponent } from '@portal/boards/components/widget/components/widget-tag-analysis/widget-tag-analysis.component';
import { WidgetTagComparisonComponent } from '@portal/boards/components/widget/components/widget-tag-comparison/widget-tag-comparison.component';
import { WidgetTagTrendsComponent } from '@portal/boards/components/widget/components/widget-tag-trends/widget-tag-trends.component';
import { WidgetTopActorsComponent } from '@portal/boards/components/widget/components/widget-top-actors/widget-top-actors.component';
import { WidgetTopChannelsComponent } from '@portal/boards/components/widget/components/widget-top-channels/widget-top-channels.component';
import { WidgetTopicClustersComponent } from '@portal/boards/components/widget/components/widget-topic-clusters/widget-topic-clusters.component';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { TopChannel } from '@portal/boards/models/widgets/top-channel';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import { TagAnalysis } from '../../boards/models/widgets/tag-analysis';
import { TagComparison } from '../../boards/models/widgets/tag-comparison';
import { TimeSeries } from '../../boards/models/widgets/time-series';
import { Timeline } from '../../boards/models/widgets/timeline';
import {
  BarMode,
  ChartOrientation,
  ChartType,
  FoamtreeMode,
} from '../enums/chart.enum';
import { AnalysisTypes, DateInterval } from '../enums/widget.enum';
import {
  IEventTimeline,
  ITagAnalysis,
  ITagComparison,
  ITimeSeries,
  ITopActor,
  ITopChannel,
  ITopicClustering,
} from '../interfaces/widget.interface';
import { Query } from '../models/query-model';

export class WidgetFactory {
  static createWidget(
    widget: any,
  ):
    | TagAnalysis
    | TagComparison
    | TimeSeries
    | Timeline
    | EventTimeline
    | TopActor
    | TopChannel
    | TopicClustering {
    if (widget.type === AnalysisTypes.TAG_ANALYSIS) {
      const tagAnalysis = widget as ITagAnalysis;
      return new TagAnalysis(
        tagAnalysis.widgetId,
        tagAnalysis.username,
        tagAnalysis.type,
        tagAnalysis.name,
        tagAnalysis.description,
        tagAnalysis.width || 30,
        tagAnalysis.height || 5,
        tagAnalysis.x || 0,
        tagAnalysis.y || 0,
        tagAnalysis.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagAnalysis.dataSource || [],
        tagAnalysis.dataPoints,
        tagAnalysis.top || 10,
        tagAnalysis.chartType || ChartType.BAR,
        tagAnalysis.ecosystemId,
        tagAnalysis.updatedDate || 0,
      );
    } else if (widget.type === AnalysisTypes.TAG_COMPARISON) {
      const tagComparison = widget as ITagComparison;
      return new TagComparison(
        tagComparison.widgetId,
        tagComparison.username,
        tagComparison.type,
        tagComparison.name,
        tagComparison.description,
        tagComparison.width || 30,
        tagComparison.height || 5,
        tagComparison.x || 0,
        tagComparison.y || 0,
        tagComparison.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagComparison.dataSource || [],
        tagComparison.dataValues,
        tagComparison.dataType,
        tagComparison.chartType || ChartType.BAR,
        tagComparison.ecosystemId,
        tagComparison.updatedDate || 0,
      );
    } else if (widget.type === AnalysisTypes.TIME_SERIES) {
      const timeSeries = widget as ITimeSeries;
      return new TimeSeries(
        timeSeries.widgetId,
        timeSeries.username,
        timeSeries.type,
        timeSeries.name,
        timeSeries.description,
        timeSeries.width || 30,
        timeSeries.height || 5,
        timeSeries.x || 0,
        timeSeries.y || 0,
        timeSeries.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        timeSeries.dataSource || [],
        timeSeries.dataValues,
        timeSeries.dataType,
        timeSeries.chartType || ChartType.BAR,
        timeSeries.interval || DateInterval.DAY,
        timeSeries.ecosystemId,
        timeSeries.updatedDate || 0,
      );
    } else if (widget.type === AnalysisTypes.TIMELINE) {
      const timeline = widget as any;
      let extras: Query;
      if (timeline.query) {
        extras = new Query();
        extras.query = extras.dtoToModel(timeline.query);
        extras.type = JSON.parse(timeline._extras).type;
      }
      return new Timeline(
        timeline.widgetId,
        timeline.username,
        timeline.type,
        timeline.name,
        timeline.description,
        timeline.chartType,
        timeline.ecosystemId,
        timeline.width,
        timeline.height,
        timeline.x,
        timeline.y,
        timeline.dataSource,
        timeline.createdDate,
        timeline.sharedTag,
        extras,
        timeline.categoryId,
        timeline.lastTaggingTime,
        timeline.updatedDate || 0,
        timeline.alertTypeId,
        timeline.options,
        timeline.dataPoints,
      );
    } else if (widget.type === AnalysisTypes.EVENT_TIMELINE) {
      const eventTimeline = widget as IEventTimeline;
      return new EventTimeline(
        eventTimeline.widgetId,
        eventTimeline.username,
        eventTimeline.type,
        eventTimeline.name,
        eventTimeline.description,
        eventTimeline.width || 25,
        eventTimeline.height || 5,
        eventTimeline.x || 0,
        eventTimeline.y || 0,
        eventTimeline.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        eventTimeline.dataSource || [],
        eventTimeline.chartType || ChartType.BAR,
        eventTimeline.ecosystemId,
        eventTimeline.updatedDate || 0,
        eventTimeline.dataCorrelation,
      );
    } else if (widget.type === AnalysisTypes.TOPIC_CLUSTERS) {
      const topicCluster = widget as ITopicClustering;
      return new TopicClustering(
        topicCluster.widgetId,
        topicCluster.username,
        topicCluster.type || AnalysisTypes.TOPIC_CLUSTERS,
        topicCluster.name,
        topicCluster.description,
        topicCluster.width || 33,
        topicCluster.height || 8,
        topicCluster.x || 0,
        topicCluster.y || 0,
        topicCluster.dataSource,
        topicCluster.chartType || ChartType.FOAMTREE,
        topicCluster.ecosystemId,
        topicCluster.updatedDate,
        topicCluster.options || {
          theme: 'intorqa',
          layout: FoamtreeMode.POLYGONAL,
        },
      );
    } else if (widget.type === AnalysisTypes.TOP_ACTORS) {
      const topActor = widget as ITopActor;
      return new TopActor(
        topActor.widgetId,
        topActor.username,
        topActor.type || AnalysisTypes.TOP_ACTORS,
        topActor.name,
        topActor.description,
        topActor.width || 25,
        topActor.height || 8,
        topActor.x || 0,
        topActor.y || 0,
        topActor.dataSource,
        topActor.chartType || ChartType.TABLE,
        topActor.ecosystemId,
        topActor.updatedDate,
        topActor.top || 10,
        topActor.options,
      );
    } else if (widget.type === AnalysisTypes.TOP_CHANNELS) {
      const topChannel = widget as ITopChannel;
      return new TopChannel(
        topChannel.widgetId,
        topChannel.username,
        topChannel.type || AnalysisTypes.TOP_CHANNELS,
        topChannel.name,
        topChannel.description,
        topChannel.width || 25,
        topChannel.height || 8,
        topChannel.x || 0,
        topChannel.y || 0,
        topChannel.dataSource,
        topChannel.chartType || ChartType.TABLE,
        topChannel.ecosystemId,
        topChannel.updatedDate,
        topChannel.top || 10,
        topChannel.options,
      );
    }
  }

  static createWidgetByType(
    type: AnalysisTypes,
    ecosystemId: string,
  ):
    | TagAnalysis
    | TagComparison
    | TimeSeries
    | Timeline
    | TopicClustering
    | TopActor
    | TopChannel
    | EventTimeline {
    if (type === AnalysisTypes.TAG_ANALYSIS) {
      return new TagAnalysis(
        undefined,
        undefined,
        AnalysisTypes.TAG_ANALYSIS,
        undefined,
        '',
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
        },
        [],
        [],
        10,
        ChartType.BAR,
        ecosystemId,
        undefined,
      );
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return new TagComparison(
        undefined,
        undefined,
        AnalysisTypes.TAG_COMPARISON,
        undefined,
        undefined,
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
          mode: BarMode.STACK,
        },
        [],
        [],
        undefined,
        ChartType.BAR,
        ecosystemId,
        undefined,
      );
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return new TimeSeries(
        undefined,
        undefined,
        AnalysisTypes.TIME_SERIES,
        undefined,
        undefined,
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
          mode: BarMode.STACK,
        },
        [],
        [],
        TagCategory['My Tags'],
        ChartType.BAR,
        DateInterval.DAY,
        ecosystemId,
        undefined,
      );
    } else if (type === AnalysisTypes.TIMELINE) {
      return new Timeline(
        undefined,
        undefined,
        AnalysisTypes.TIMELINE,
        undefined,
        undefined,
        ChartType.TIMELINE,
        ecosystemId,
        25,
        8,
        0,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
    } else if (type === AnalysisTypes.TOP_CHANNELS) {
      return new TopChannel(
        undefined,
        undefined,
        AnalysisTypes.TOP_CHANNELS,
        undefined,
        undefined,
        25,
        8,
        0,
        0,
        undefined,
        ChartType.TABLE,
        ecosystemId,
        undefined,
        10,
        {},
      );
    } else if (type === AnalysisTypes.TOP_ACTORS) {
      return new TopActor(
        undefined,
        undefined,
        AnalysisTypes.TOP_ACTORS,
        undefined,
        undefined,
        25,
        8,
        0,
        0,
        undefined,
        ChartType.TABLE,
        ecosystemId,
        undefined,
        10,
        {},
      );
    } else if (type === AnalysisTypes.TOPIC_CLUSTERS) {
      return new TopicClustering(
        undefined,
        undefined,
        AnalysisTypes.TOPIC_CLUSTERS,
        undefined,
        undefined,
        33,
        8,
        0,
        0,
        [],
        ChartType.FOAMTREE,
        ecosystemId,
        undefined,
        { theme: 'intorqa', layout: FoamtreeMode.POLYGONAL },
      );
    } else if (type === AnalysisTypes.EVENT_TIMELINE) {
      return new EventTimeline(
        undefined,
        undefined,
        AnalysisTypes.EVENT_TIMELINE,
        undefined,
        undefined,
        32,
        8,
        0,
        0,
        { orientation: TimelineOrientation.VERTICAL },
        [],
        ChartType.EVENT_TIMELINE,
        ecosystemId,
        undefined,
        [],
      );
    }
  }

  static getComponent(
    type: AnalysisTypes,
  ): Type<
    | WidgetTagAnalysisComponent
    | TimelineWidgetComponent
    | WidgetTagTrendsComponent
    | WidgetTagComparisonComponent
    | WidgetTopActorsComponent
    | WidgetTopChannelsComponent
    | WidgetEventTimelineComponent
    | WidgetTopicClustersComponent
  > {
    if (type === AnalysisTypes.TAG_ANALYSIS) {
      return WidgetTagAnalysisComponent;
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return WidgetTagTrendsComponent;
    } else if (type === AnalysisTypes.TIMELINE) {
      return TimelineWidgetComponent;
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return WidgetTagComparisonComponent;
    } else if (type === AnalysisTypes.TOP_ACTORS) {
      return WidgetTopActorsComponent;
    } else if (type === AnalysisTypes.TOP_CHANNELS) {
      return WidgetTopChannelsComponent;
    } else if (type === AnalysisTypes.EVENT_TIMELINE) {
      return WidgetEventTimelineComponent;
    } else if (type === AnalysisTypes.TOPIC_CLUSTERS) {
      return WidgetTopicClustersComponent;
    }
  }
}
