import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Event, EventTeams } from '@intorqa-ui/core';
import { EventsService } from '@portal/shared/services/events.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class EventComponent implements OnInit {
  @Input() events: Array<Event>;
  @Input() allowDrilldown = true;

  readonly EventTeams = EventTeams;

  public expandedEvent: string;
  private subscriptions = new Subscription();

  constructor(readonly eventService: EventsService) {}

  ngOnInit() {
    this.bindExpandEventsubscription();
  }

  private bindExpandEventsubscription(): void {
    if (this.allowDrilldown) {
      this.subscriptions.add(
        this.eventService.expandEvent$.subscribe(() => {
          this.expandedEvent = undefined;
        }),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.allowDrilldown) {
      this.subscriptions.unsubscribe();
    }
  }

  public onClickEvent(event: Event): void {
    if (this.expandedEvent === event.id) {
      this.eventService.expandEvent$.next();
    } else {
      this.eventService.expandEvent$.next();
      this.expandedEvent = event.id;
    }
    this.eventService.drilldown$.next(event);
  }
}
