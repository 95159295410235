import { TemplateRef } from '@angular/core';
import {
  DTOQuery,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  QueryFilters,
  TableColumn,
} from '@intorqa-ui/core';
import { TOP_CHANNELS_CHART_TYPES } from '@portal/boards/const/top-channels.const';
import {
  ChartOption,
  ChartOrientation,
  ChartType,
} from '@portal/shared/enums/chart.enum';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
  ITopChannel,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Widget } from './widget';

export class TopChannel extends Widget implements ITopChannel {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public dataSource: Array<string>,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
    public top: number,
    public options: { [key: string]: any },
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
    };
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TOP_CHANNELS_CHART_TYPES;
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: 'Channel',
        dataKey: 'category',
        width: 'fit',
        isSortable: true,
      },
      {
        name: 'Count',
        dataKey: 'count',
        position: 'right',
        width: '100px',
        customRender: true,
        template: template,
        isSortable: true,
      },
    ];
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    const filteredSeries = series.filter((item: ISerie) => {
      return item.data.length > 0;
    });
    return filteredSeries.map((serie: ISerie) => {
      return {
        ...serie,
        data: serie.data.sort((a: IDataPoint, b: IDataPoint) => {
          if (this.options.orientation === ChartOrientation.VERTICAL) {
            return a.count > b.count ? -1 : 1;
          } else {
            return a.count > b.count ? 1 : -1;
          }
        }),
      };
    });
  }

  public getDocsQueryModel(initialState: QueryFilters, segment: any): DTOQuery {
    const queryModel = new Query();
    if (initialState?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [initialState.query],
        ),
      );
    }

    queryModel.addRule(
      new QueryRule(DTOQueryFieldType.filter, DTOQueryConditionOperator.in, [
        segment?.data?.tagId,
      ]),
    );

    return queryModel.modelToDTO();
  }

  public getDisplayType(): IDisplayType {
    if (this.chartType === ChartType.TABLE) {
      return TOP_CHANNELS_CHART_TYPES.find((displayType: IDisplayType) => {
        return displayType.type === this.chartType;
      });
    } else {
      if (this.options?.orientation) {
        return TOP_CHANNELS_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            this.options.orientation &&
            this.options.orientation === displayType.options?.orientation
          );
        });
      }
    }
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0 ? true : false;
  }

  public cloneDeep(): TopChannel {
    return new TopChannel(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.dataSource,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
      this.top,
      this.options,
    );
  }
}
