<li class="pl-4" *ngFor="let item of dataSource; trackBy: trackByMethod">
  <div class="list__item_container">
    <span
      [ngClass]="{
        noresults: item.count === 0,
        'font-mont block overflow-hidden text-ellipsis whitespace-nowrap': true
      }"
    >
      {{ item.name }}
      @if (item.count) {
        <strong>({{ item.count }})</strong>
      }
    </span>
    <div class="item__container">
      <itq-mat-button
        [materialStyle]="'basic'"
        [type]="'button'"
        [matTooltip]="'Include tag'"
        (clickEvent)="
          onChange($event, {
            tagId: item.id,
            included: true,
            excluded: false,
            categoryName: category,
            tagName: item.name,
            section
          })
        "
      >
        <fa-icon [icon]="['far', 'circle-plus']"></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        [materialStyle]="'basic'"
        [type]="'button'"
        [matTooltip]="'Exclude tag'"
        (clickEvent)="
          onChange($event, {
            tagId: item.id,
            included: false,
            excluded: true,
            categoryName: category,
            tagName: item.name,
            section
          })
        "
      >
        <fa-icon [icon]="['far', 'circle-minus']"></fa-icon>
      </itq-mat-button>
    </div>
  </div>
</li>
