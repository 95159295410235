<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <itq-widget-header
    [widget]="widget"
    [selectedDisplayType]="selectedDisplayType"
    [displayTypesTemplate]="displayTypesTemplate"
    [widgetMoreTemplate]="widgetMoreTemplate"
    [count]="count"
  ></itq-widget-header>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border"
    #chartContainer
  >
    @if (topActorsWidget?.chartType === ChartType.TABLE) {
      <itq-table-chart
        class="overflow-hidden p-4"
        [dataSource]="dataSource"
        [tableColumns]="tableColumns"
        [segment]="segment"
        [trackBy]="'category'"
      ></itq-table-chart>
    } @else {
      <itq-chart
        class="p-4"
        #chart
        [id]="topActorsWidget?.widgetId"
        [data]="dataSource"
        [settings]="topActorsWidget?.options"
        [type]="topActorsWidget?.chartType"
        (updateRef)="onUpdateRef($event)"
        (drilldown)="onExplore($event)"
      >
      </itq-chart>
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onEdit()"
  >
    <fa-icon [icon]="['far', 'pencil']"></fa-icon>
    Edit
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onExplore()"
  >
    <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
    Explore
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onDeleteWidget()"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Remove
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onExportWidget()"
  >
    <fa-icon [icon]="['far', 'download']"></fa-icon>
    Export
  </itq-mat-button>
</ng-template>

<ng-template #displayTypesTemplate>
  <itq-widget-display-types
    [displayTypes]="displayTypes"
    [selectedDisplayType]="selectedDisplayType"
    (changeDisplay)="onChangeDisplay($event)"
  ></itq-widget-display-types>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count }}
  </button>
</ng-template>
