<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'expand-wide']" [size]="'2x'"></fa-icon>

      <h1 class="text-28 font-bold pl-4">
        {{ widget?.name }}
      </h1>
      @if (segment?.data?.name || segment?.data?.value?.label) {
        <itq-pill
          [class]="
            'rounded-2xl px-4 py-2 ml-7 shadow-none font-semibold bg-primary-over uppercase'
          "
          [type]="PillType.CUSTOM"
          >{{ widget.type | mapLabel }}</itq-pill
        >
      }
    </div>
    <itq-mat-button
      [materialStyle]="'stroked'"
      class="mr-2"
      (clickEvent)="onExport()"
    >
      <fa-icon [icon]="['far', 'download']"></fa-icon>
      <span class="text-wrapper">EXPORT</span>
    </itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
          count | number
        }}</itq-pill>
      </div>

      <div class="flex flex-col px-7 py-4">
        <h2 class="flex font-bold pb-2 text-xl">Search</h2>
        <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
        <itq-input
          class="w-full pb-4"
          [icon]="'search'"
          [placeholder]="'Search...'"
          formControlName="query"
          (search)="onChangeSearch()"
          (clear)="onChangeSearch()"
        ></itq-input>
        <h2 class="flex font-bold pb-4 text-xl">Filters</h2>
        <div class="flex 2 flex-col overflow-hidden">
          <span class="mb-1 text-13 font-medium flex"> DATE RANGE </span>

          <itq-date-range
            class="w-full"
            [dates]="this.initialState?.where"
            (search)="onChangeDate($event)"
          ></itq-date-range>
        </div>
      </div>
    </div>
    <div class="flex flex-1 border-r border-border flex-col">
      <div
        class="flex items-center px-7 py-3 justify-start w-full border-b border-border"
      >
        <span class="font-semibold mr-2">Display:</span>
        <itq-button-thumbnail
          [dataSource]="chartTypesDataSource"
          formControlName="chartType"
          (change)="onChangeChartType($event)"
        ></itq-button-thumbnail>
      </div>
      <div class="flex flex-1 overflow-hidden">
        <div class="flex flex-col bg-background border-r border-border flex-1">
          <div
            class="flex flex-col p-7 pt-4 flex-1 h-full min-w-[500px]"
            #chartContainer
          >
            <itq-chart
              class="h-full"
              [id]="navigationItem?.id"
              [data]="dataSource"
              [settings]="widget?.options"
              [segment]="segment"
              [type]="widget?.chartType"
              (drilldown)="onDrilldownChart($event)"
              (updateRef)="onUpdateRef($event)"
            ></itq-chart>
          </div>
        </div>
        @if (segment?.data?.value) {
          <div ngclass class="flex flex-col flex-1 min-w-[500px]">
            <div class="flex flex-1 overflow-hidden">
              @if (documentsData) {
                <itq-virtual-table
                  [initialState]="initialState"
                  [tableColumns]="docsTableColumns"
                  [dataSource]="documentsData?.items"
                  [active]="segment?.value?.id"
                  [totalCount]="documentsData?.totalHits"
                  [theme]="'tile'"
                  [headerVisible]="false"
                  [trackBy]="'id'"
                  [emptyMessage]="'Please update your filters and try again'"
                  [emptyMessageDescription]="
                    'Please update your filters and try again'
                  "
                  (dataBound)="onDataBound($event)"
                ></itq-virtual-table>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</form>

<ng-template #template let-data>
  <itq-document-item-tile-v2
    class="p-4"
    [document]="data"
    [form]="form"
    [allowDrilldown]="true"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
    (addNote)="onAddNote($event)"
    (addConnection)="onAddConnection($event)"
  ></itq-document-item-tile-v2>
</ng-template>
