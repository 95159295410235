<itq-foamtree
  *ngIf="viewMode === ViewMode.RIPL"
  [dataSource]="foamtreeData"
  (updateRef)="onUpdateRef($event)"
  (drilldown)="onDrilldownFoamtree($event)"
></itq-foamtree>
<itq-table
  *ngIf="
    widgetData && navigationItem.selectedDisplayType.type === ChartType.TABLE
  "
  [tableData]="widgetData?.series[0]?.data"
  [tableColumns]="tableColumns"
  [trackBy]="'tagId'"
>
</itq-table>
<itq-virtual-table
  *ngIf="
    timelineData &&
    (navigationItem.selectedDisplayType.type === ChartType.TIMELINE ||
      viewMode === ViewMode.RIPL)
  "
  [initialState]="initialState"
  [tableColumns]="tableColumns"
  [theme]="'tile'"
  [active]="segment?.value?.id"
  [headerVisible]="false"
  [dataSource]="timelineData?.result"
  [allowScrollingUp]="
    (navigationItem | isDiscordNavigation) || allowScrollingUp
      ? true
      : false
        ? true
        : false
  "
  [totalCount]="timelineData?.count"
  [fullScrolling]="true"
  [trackBy]="'id'"
  [emptyMessage]="'Please update your filters and try again'"
  [emptyMessageDescription]="'Please update your filters and try again'"
></itq-virtual-table>
<ng-template #template let-data>
  <itq-document-item-tile
    [item]="data"
    [segment]="segment"
    [initialState]="initialState"
    [profile]="profile"
    [form]="form"
    [navigationItem]="navigationItem"
    [query]="initialState?.query"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
    (addConnection)="onAddConnection($event)"
    (addNote)="onAddNote($event)"
  ></itq-document-item-tile>
</ng-template>

<ng-template #countTemplate let-row>
  @if (segment?.data?.value !== row.count) {
    <button
      type="button"
      class="btn btn-link-secondary"
      (click)="onLoadCount(row)"
    >
      {{ row.count }}
    </button>
  } @else {
    <span>{{ row.count }}</span>
  }
</ng-template>
